import { useRouter } from "next/router"
import { useEffect } from "react"
import PageSpinner from "app/common/PageSpinner"
import { useCurrentUser } from "app/lib/CurrentUser"

function IndexPage() {
  const router = useRouter()
  const user = useCurrentUser()
  const isOnboarding = user.isEndUser && !user.firstName

  useEffect(() => {
    if (isOnboarding) {
      router.push(`/${router.query?.organizationSlug}/welcome`)
    } else {
      router.push(`/${router.query?.organizationSlug}/home`)
    }
  }, [isOnboarding, router])

  return <PageSpinner />
}

IndexPage.withNoLayout = true
export default IndexPage
